<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="INVOICE"
          :breadcrumb="[{ label: 'Retailer' }, { label: 'Invoice' }]"
        >
        </banner>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->

          <section>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div
                  class="card custom-card"
                >
                  <div class="card-body">
                    <h3 class="text"><b>Invoice Records</b></h3>
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Company Name</th>
                          <th class="text-truncate">Service</th>
                          <th class="text-truncate">Amount</th>
                          <th class="text-truncate">Due Balance</th>
                          <th class="text-truncate">Status</th>
                          <!-- <th class="text-center text-truncate">Action</th> -->
                        </tr>

                        <tbody class="text">
                          <tr v-for="(invoice, index) in allClientServices" :key="index">
                            <td class="text-truncate">
                              <!-- <router-link
                                @click="idStore(invoice.id)"
                                :to="`/super-admin/client-invoice-view/${invoice.id}`"
                              >
                                {{ invoice.clientServiceNo }}
                              </router-link> -->

                              {{ invoice.clientServiceNo }}
                            </td>
                            <td class="text-truncate">
                              {{ dateTime(invoice.date) }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="invoice.business">
                                {{ invoice.business.name }}</span
                              >
                            </td>
                            <td class="text-truncate">
                              <span v-if="invoice.service">{{
                                invoice.service.name
                              }}</span>
                            </td>
                            <td class="text-truncate">
                              Rs.{{ invoice.serviceChargeGst + invoice.serviceCharge }}
                            </td>
                            <td class="text-truncate">Rs. <span v-if="invoice">{{
                                  getBallance(invoice)
                                }}</span></td>
                                <td
                                v-if="invoice.client"
                                class="text-truncate"
                                style="text-transform: capitalize"
                              >
                                <p
                                  v-if="invoice.status == 'assigned'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>
                                <p
                                  v-if="invoice.status == 'completed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 90px;
                                    background-color: #008000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>

                                <p
                                  v-if="invoice.status == 'pending'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #cdcdcd;
                                    color: white;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>New</b>
                                </p>
                                <p
                                  v-if="invoice.status == 'processed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #ffaa00;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>
                                <p
                                  v-if="invoice.status == 'hold'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #8f8d8e;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>
                                <p
                                  v-if="invoice.status == 'rejected'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>
                                <p
                                  v-if="invoice.status == 'cancelled'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>
                                <p
                                  v-if="invoice.status == 'upgrade'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #ee0def;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ invoice.status }}</b>
                                </p>
                              </td>
                            <!-- <td class="text-end">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <button
                                  type="button"
                                  class="btn btn-sm text-white"
                                  style="
                                    background-color: #00364f;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card-footer">
                    <div class="row">
                      <div class="col-7">
                        <ul class="pagination">
                          <li class="page-item" @click="lodaInvoices(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="lodaInvoices(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 3"
                            @click="lodaInvoices(pagination.current_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 2"
                            @click="lodaInvoices(pagination.current_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 1"
                            @click="lodaInvoices(pagination.current_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li
                            class="active page-item"
                            @click="lodaInvoices(pagination.current_page)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 4"
                            @click="lodaInvoices(pagination.current_page + 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 5"
                            @click="lodaInvoices(pagination.current_page + 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 6"
                            @click="lodaInvoices(pagination.current_page + 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="lodaInvoices( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="lodaInvoices( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 3"
                            @click="lodaInvoices(pagination.last_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 2"
                            @click="lodaInvoices(pagination.last_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 1"
                            @click="lodaInvoices(pagination.last_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 1
                            }}</a>
                          </li>

                          <li class="page-item" @click="lodaInvoices(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="lodaInvoices(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-4 text-end">
                        Showing {{ pagination.from }} to {{ pagination.to }} of
                        {{ pagination.total }} entries
                      </div>
                      <div
                        class="col-1 text-end"
                        style="height: 30px; padding: 5px 5px 5px 5px"
                      >
                        <select
                          style="height: 30px; padding: 5px 5px 5px 5px"
                          class="form-select"
                          @change="changePageNo"
                          aria-label="Default select example"
                        >
                      
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style="color: #00364f; font-size: 1.7vw"
          >
            PayMent
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body border">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th style="background-color: #f2f2f2">PayMent ID</th>
                <td>#3021</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">Amount</th>
                <td>Rs. 144650.00</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">PayMent ID</th>
                <td>#3021</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">Amount</th>
                <td>Rs. 144650.00</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">PayMent ID</th>
                <td>#3021</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">Amount</th>
                <td>Rs. 144650.00</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">PayMent ID</th>
                <td>#3021</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">Amount</th>
                <td>Rs. 144650.00</td>
              </tr>
              <tr>
                <th style="background-color: #f2f2f2">PayMent ID</th>
                <td>#3021</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            style="background-color: red"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

<script>
import moment from "moment";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
export default {
  name: "Ivoice",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      allClientServices: [],
    };
  },
  methods: {
    getBallance(client_service) {
      var bal = 0;
      var payments = client_service.client_payment;
      for (var i = 0; i < payments.length; i++) {
        bal += payments[i].amt;
      }
      bal = client_service.serviceCharge + client_service.serviceChargeGst - bal;
      return bal;
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.lodaInvoices();
    },
    dateTime(value) {
      console.log(value);
      return moment(value).format("DD-MM-YYYY");
    },
    idStore(id) {
      var Invoice = {};
      // itr = JSON.parse(localStorage.getItem('in'))
      Invoice.id = id;
      localStorage.setItem("Invoice", JSON.stringify(Invoice));
    },
    lodaInvoices(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/clientservice?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.allClientServices = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.lodaInvoices();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #fefeff;
  border-color: #f21003;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

th {
  width: 200px;
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
</style>
